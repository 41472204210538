import React, { useEffect, useState } from 'react';
import { getBarGraphDataApi, getLineGraphDataApi } from '../../targetactioncreator';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import Loader from '../../../../../presentationals/Loader/Loader.component'

export default function OverviewSection({ selectedData }) {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filterType, setFilterType] = useState('month');
    const [filterTypeBar, setFilterTypeBar] = useState('month');
    const [loader, setLoader] = useState(false)

    const [chartData, setChartData] = useState({
        series: [{
            name: "",
            data: []
        }],
        options: {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            title: {
                text: '',
                align: 'left'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5
                }
            },
            xaxis: {
                categories: []
            }
        }
    });

    const [chartDataBar, setChartDataBar] = useState({
        series: [{
            name: "Expense",
            data: []
        }, {
            name: "Target",
            data: []
        }],
        options: {
            chart: {
                height: 350,
                type: 'bar',
                zoom: { enabled: false }
            },
            dataLabels: { enabled: false },
            title: { text: '', align: 'left' },
            grid: {
                row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.5 }
            },
            xaxis: { categories: [] }
        }
    });

    useEffect(() => {
        // if (startDate && endDate) {
        fetchGraphData();
        // }
    }, []);

    const fetchGraphData = () => {
        // getLineGraphDataApi(selectedData.expenseCategoryId, moment(startDate).format('DD-MM-YYYY'), moment(endDate).format('DD-MM-YYYY'), filterType, (callback) => {
        //     makeChartData(callback.data);
        // });
        setLoader(true)
        getBarGraphDataApi(selectedData.expenseCategoryId,startDate!==''? moment(startDate).format('DD-MM-YYYY'):"", endDate!==''?moment(endDate).format('DD-MM-YYYY'):"", filterTypeBar, (callback) => {
            makeChartDataBar(callback.data);
            setLoader(false)

        });
    };

    const makeChartData = (data) => {
        const categories = data.map(item => item.period);
        const values = data.map(item => item.value);

        setChartData({
            series: [{
                name: "Desktops",
                data: values
            }],
            options: {
                ...chartData.options,
                xaxis: {
                    categories: categories
                }
            }
        });
    };

    const makeChartDataBar = (data) => {
        const categories = data.map(item => item.period);
        const expenseValues = data.map(item => item.expense !== null ? item.expense : 0);
        const targetValues = data.map(item => item.target);

        setChartDataBar({
            series: [
                {
                    name: "Expense",
                    data: expenseValues
                },
                {
                    name: "Target",
                    data: targetValues
                }
            ],
            options: {
                ...chartData.options,
                xaxis: {
                    categories: categories
                }
            }
        });
    };

    const filterHandler = (type) => {
        setFilterType(type);
        fetchGraphData();
    };

    const filterHandlerBar = (type) => {
        setFilterTypeBar(type);
        fetchGraphData();
    };

    const filterApply = () => {
        fetchGraphData();
    };

    return (
        <div className="overViewSection">
            {loader === false ? <>
                <div className='row' style={{ marginTop: '20px' }}>
                    <div className='col-sm-2 col-xs-12'>
                        <label>Start Date</label>
                        <input
                            type='date'
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className='form-control'
                        />
                    </div>
                    <div className='col-sm-2 col-xs-12'>
                        <label>End Date</label>
                        <input
                            type='date'
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            className='form-control'
                        />
                    </div>
                    <div className='col-sm-2 col-xs-12'>
                        <button
                            className='btn btn-primary'
                            style={{ marginTop: '22px',padding:'10px' }}
                            onClick={filterApply}
                        >
                            Apply Filter
                        </button>
                    </div>
                </div>
                <div className="graphs">
                    <div className="row">
                        <div className="col-sm-12">
                        <div style={{ display: 'flex', float: 'right', marginTop: '30px' }}>
                                    <button
                                        className='btn btn-default'
                                        onClick={() => filterHandler('day')}
                                        style={{ background: filterType === 'day' ? '#417bf6' : '', color: filterType === 'day' ? '#fff' : '',borderTopRightRadius:'0px',borderBottomRightRadius:'0px' }}
                                    >
                                        Day
                                    </button>
                                    <button
                                        className='btn btn-default'
                                        onClick={() => filterHandler('week')}
                                        style={{ background: filterType === 'week' ? '#417bf6' : '', color: filterType === 'week' ? '#fff' : '' ,borderRadius:'0px'}}
                                    >
                                        Weekly
                                    </button>
                                    <button
                                        className='btn btn-default'
                                        onClick={() => filterHandler('month')}
                                        style={{ background: filterType === 'month' ? '#417bf6' : '', color: filterType === 'month' ? '#fff' : '',borderTopLeftRadius:'0px',borderBottomLeftRadius:'0px' }}
                                    >
                                        Month
                                    </button>
                                </div>
                        </div>
                        <div className="col-lg-6 col-xs-12">
                            <div>
                                {/* <div style={{ display: 'flex', float: 'right', width: '100%', marginTop: '30px',visibility:'hidden' }}>
                                    <button
                                        className='btn btn-default'
                                        onClick={() => filterHandlerBar('day')}
                                        style={{ background: filterTypeBar === 'day' ? '#417bf6' : '', color: filterTypeBar === 'day' ? '#fff' : '', marginLeft: '66%' }}
                                    >
                                        Day
                                    </button>
                                    <button
                                        className='btn btn-default'
                                        onClick={() => filterHandlerBar('week')}
                                        style={{ background: filterTypeBar === 'week' ? '#417bf6' : '', color: filterTypeBar === 'week' ? '#fff' : '' }}
                                    >
                                        Weekly
                                    </button>
                                    <button
                                        className='btn btn-default'
                                        onClick={() => filterHandlerBar('month')}
                                        style={{ background: filterTypeBar === 'month' ? '#417bf6' : '', color: filterTypeBar === 'month' ? '#fff' : '' }}
                                    >
                                        Month
                                    </button>
                                </div> */}
                                <div className="boxs">
                                    <h5>Account Balance</h5>
                                    <ReactApexChart
                                        options={chartDataBar.options}
                                        series={chartDataBar.series}
                                        type="line"
                                        height={350}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xs-12">
                            <div>
                                <div className="boxs">
                                    <h5 style={{visibility:'hidden'}}>Account Balance</h5>

                                    <ReactApexChart
                                        options={chartDataBar.options}
                                        series={chartDataBar.series}
                                        type="bar"
                                        height={350}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
                : ""}
            {loader ?
                <Loader />
                : ""}
        </div>
    );
}
