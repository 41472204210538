import { APIS } from "../../../../utils/api-factory"
import { POST_HEADER } from "../../../../utils/apiHeaders"

export const getPersonalAnalysisReport = (
  userId,
  loanId,
  callBack
) => {
  fetch(APIS.GET_PERSONAL_ANALYSIS_REPORT + userId + '&loanId=' + loanId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
// export const getDashboardVerifyData = (
//   userId,
//   callBack
// ) => {
//   fetch(APIS.GET_ANALYSIS_DASHBOARD_MATCH_DATA + userId)
//     .then(res => res.json())
//     .then(json => {
//       callBack(json)
//     })
//     .catch((e) => {
//       callBack('')
//     })
// }
export const getDashboardVerifyData = (userId, loanId, callBack) => {
  return dispatch => {
    fetch(APIS.GET_ANALYSIS_DASHBOARD_MATCH_DATA + userId + '&loanId=' + loanId)
      .then(res => res.json())
      .then(json => {
        json.userId = userId
        dispatch({ type: 'GET_CREDIT_DASHBOARD_DATA', creditDashBoardDataValue: json })
        callBack(json)
      })
  }
}
export const getConcludeDataApi = (
  userId,
  loanId,
  callBack
) => {
  fetch(APIS.GET_CONCLUDED_DATA + userId + '&loanId=' + loanId)
    .then(res => res.json())
    .then(json => {
      json.userId = userId
      dispatch({ type: 'GET_CREDIT_CONCLUDED_DATA', creditConcludedData: json })
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const getSmsAnalysisStatus = (
  userId,
  callBack
) => {
  fetch(APIS.GET_SMS_ANALYSIS_STATUS + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const getCrifAnalysisStatus = (
  userId,
  loanId,
  callBack
) => {
  fetch(APIS.GET_CRIF_ANALYSIS_STATUS + userId + '&loanId=' + loanId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const getCrifAnalysisStatusExperian = (
  userId,
  loanId,
  callBack
) => {
  fetch(APIS.GET_CRIF_ANALYSIS_STATUS_EXPERIAN + userId + '&loanId=' + loanId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const getReportHitByAPI = (
  userId,
  callBack
) => {
  fetch(APIS.GET_REPORT_HIT_BY_USERID + userId)
    .then(res => res.text())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const getFinbitAnalysis = (
  userId,
  callBack
) => {
  fetch(APIS.GET_FINBIT_ANALYSIS_DATA + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const similarProfileData = (
  loanId,
  callBack
) => {
  fetch(APIS.GET_SIMILAR_ANALYSIS_DATA + loanId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const saveCommentForCreditAssesment = (loanId, userId, notesText, emailId, notesType, callBack) => {
  var data = {
    loanId: loanId,
    userId: userId,
    notes: notesText,
    empId: emailId,
    notesType: notesType

  }
  fetch(APIS.SAVE_CREDIT_ASSESMENT_NOTES, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}
export const getNotes = (
  loanId,
  userId,
  notesType,
  callBack
) => {
  var data = {


  }
  // fetch(APIS.GET_CREDIT_ASSESSMENT_COMMENT + loanId + ' &userId=' + userId + '&notesType=' + notesType)
  //   .then(res => res.json())
  //   .then(json => {
  //     callBack(json)
  //   })
  //   .catch((e) => {
  //     callBack('')
  //   })
  fetch(APIS.GET_CREDIT_ASSESSMENT_COMMENT + loanId + '&userId=' + userId + '&notesType=' + notesType, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(text => {
      callBack(text)
    })
}
export const getCreditAnalysisData = (
  userId,
  loanId,
  policyId,
  empId,
  callBack
) => {
  fetch(APIS.GET_CREDIT_ANALYSIS_DATA + userId + '&loanId=' + loanId + '&policyId=' + policyId +'&empId='+empId )
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const getAllPolicyApi = (

  callBack
) => {
  fetch(APIS.GET_ALL_POLICY_LIST)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}

export const updatePolicyDetails = (data, callBack) => {

  fetch(APIS.SAVE_OR_UPDATE_CREDIT_POLICY_LIST, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}
export const getNotesnew = (
  loanId,
  userId,
  notesType,
  callBack
) => {
  var data = {}
  fetch(APIS.GET_NOTES_API_NEW + loanId + '&userId=' + userId + '&notesType=' + notesType, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.json())
    .then(text => {
      callBack(text)
    })
}
export const kycAnalysisSideApi = (
  userId,
  callBack
) => {
  fetch(APIS.GET_ANALYSIS_API_NEW + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const creditDashboardApi = (
  userId,
  loanId,
  aggregator,
  callBack
) => {
  fetch(APIS.CREDIT_ANALYSIS_NEW_API + userId + '&loanId=' + loanId + '&aggregator=' + aggregator)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}


export const saveWorkingHistoryApi = (dataToSave, callBack) => {
  var data = {
    crifData: dataToSave.crifData,
    finbitData: dataToSave.finbitData,
    dedupeData: dataToSave.dedupeData,
    smsData: dataToSave.smsData,
    kycData: dataToSave.kycData,
    userId: dataToSave.userId,
    loanId: dataToSave.loanId,
    empId: dataToSave.empId
  }
  fetch(APIS.SAVE_WORKING_HISTRORY_API, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}
export const getWorkingHistoryData = (
  userId,
  callBack
) => {
  fetch(APIS.GET_WORKING_HISTORY_DATA + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const getOgDetailsApi = (
  userId,
  callBack
) => {
  fetch(APIS.GET_ORG_DETAILS + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const matchFaceApi = (
  userId,
  callBack
) => {
  fetch(APIS.FACE_MATCH_API + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const getFaceData = (
  userId,
  callBack
) => {
  fetch(APIS.GET_FACE_DATA + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const getSmsAnalysisStatusWithRegenerate = (
  userId,
  regenerate,
  callBack
) => {
  fetch(APIS.GET_SMS_ANALYSIS_STATUS + userId + '&regenerate=' + regenerate)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const getAmlData = (
  userId,
  callBack
) => {
  // fetch(APIS.GET_AML_DATA_BY_USERID + 'testUser987654321e6PYasFER4LFe6WELn')

  fetch(APIS.GET_AML_DATA_BY_USERID + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}

export const getkycBoxApi = (
  userId,
  applicationId,
  loanId,
  callBack
) => {
  // fetch(APIS.GET_AML_DATA_BY_USERID + 'testUser987654321e6PYasFER4LFe6WELn')

  fetch(APIS.GET_KYC_BOX_DATA + userId + '&applicationId=' + applicationId + '&loanId=' + loanId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const saveKycBoxDataApi = (data, callBack) => {

  fetch(APIS.SAVE_KYC_UNDERWRITTER + data.userId + '&aadhaarLinkedMobile=' + data.aadhaarLinkedMobile + '&applicationId=' + data.applicationId + '&underwriterEmail=' + data.underwriterEmail + '&result=' + data.result + '&loanId=' + data.loanId, {
    method: 'POST',
    headers: POST_HEADER,
    body: JSON.stringify(data)
  })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}
export const getNewInternalDedupeBoxData = (
  userId,
  loanId,
  callBack
) => {
  // fetch(APIS.GET_AML_DATA_BY_USERID + 'testUser987654321e6PYasFER4LFe6WELn')

  fetch(APIS.GET_INTERNAL_DEDUPE_DATA + userId + '&loanId=' + loanId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const saveDedupeDataDataApi = (data, callBack) => {

  fetch(APIS.SAVE_DEDUPEDATA + data.userId + '&loanId=' + data.loanId + '&underwriterEmail=' + data.underwriterEmail + '&result=' + data.result + '&duplicatePrimaryNumberDecision=' + data.duplicatePrimaryNumberDecision + '&duplicatePrimaryEmailDecision=' + data.duplicatePrimaryEmailDecision + '&duplicateAddressDecision=' + data.duplicateAddressDecision + '&duplicateAltNumberDecision=' + data.duplicateAltNumberDecision + '&duplicateOfficialEmailDecision=' + data.duplicateOfficialEmailDecision
    , {
      method: 'POST',
      headers: POST_HEADER,
      body: JSON.stringify(data)
    })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}
export const getOrganisationAnalysisData = (
  userId,
  applicationId,
  callBack
) => {
  // fetch(APIS.GET_AML_DATA_BY_USERID + 'testUser987654321e6PYasFER4LFe6WELn')

  fetch(APIS.GET_ORGANISATION_ANALYSIS_DATA + userId + '&applicationId=' + applicationId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const updateLatestStatementOrNot = (data, callBack) => {

  fetch(APIS.UPDATE_BANK_STATEMENT_STATUS + data.loanId + '&userId=' + data.userId + '&status=' + data.status
    , {
      method: 'POST',
      headers: POST_HEADER,
      body: JSON.stringify(data)
    })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}
export const UpdateNewUserStatus = (data, callBack) => {

  fetch(APIS.UPDATE_REAPEAT_CUSTOMER + data.loanId + '&userId=' + data.userId + '&status=' + data.status
    , {
      method: 'POST',
      headers: POST_HEADER,
      body: JSON.stringify(data)
    })
    .then(res => res.text())
    .then(text => {
      callBack(text)
    })
}
export const getAnalysisDataApiNew = (
  userId,
  callBack
) => {
  // fetch(APIS.GET_AML_DATA_BY_USERID + 'testUser987654321e6PYasFER4LFe6WELn')

  fetch(APIS.GET_DATA_ANALYSIS_STATUS_BY_USERID + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const getAMlPEPDataApi = (
  userId,
  callBack
) => {
  fetch(APIS.GET_TRACKWIZZ_RESPONSE + userId)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}
export const organizationVerifyByApi = (
  userId,
  value,
  callBack
) => {

  fetch(APIS.SET_ORGANIZATION_API + userId + '&value=' + value)
    .then(res => res.json())
    .then(json => {
      callBack(json)
    })
    .catch((e) => {
      callBack('')
    })
}