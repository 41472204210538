import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  saveTentativeDataApi,
  getUserDetailByLoanId,
  getNbfcList,
  getLoanDetailByUserId,
  saveTentativeDataGiftCard,
  rfdApi,
  triggerKycApi,
  getSourceOfEnach,
  getUserDetailByLoanIdOptimisedApi

} from '../AdminActionCreator.component'
import { calculateEmiApi, calculateEmiApiGiftCard } from '../../Forms/PhocketActionCreator.component'
import { mandateVarificationApi, calculateEmiNbfcApi, bankVerificationApi } from '../AdminActionCreator.component'
import Popup from '../../../presentationals/Popup/Popup.component'
import { _formatDateInDash } from '../../../../utils/validation'
import UserFinancial from '../../../presentationals/Popup/TentativePopup'
import BulletToEmiPopup from '../../Dashboard/BulletToEmi.container'
import { APIS } from '../../../../utils/api-factory'
import { TYPE } from '../../../../utils/Constant'
import Loader from '../../../presentationals/Loader/Loader.component'
import LoaderGift from '../../../presentationals/Loader/Loader.component'

import GiftPopup from '../../../presentationals/Popup/giftCardPopup.component'
import NewLoader from '../../../presentationals/Loader/Loader.component'
import ConfirmationPopup from '../../../presentationals/Popup/ConfirmationPopup.component'
import moment from 'moment'
class FinancialUser extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaderState: false,
      nbfc: 'RTcTsJVH',
      nbfcList: '',
      allDetail: this.props.allDetail,
      repeatApplication: false,
      tentativePopupState: false,
      popupState: false,
      popupStatus: '',
      lastEmiDate: '',
      BulletToEmiState: false,
      loanDataForEmiChange: '',
      tentativeDetail: {
        tentativeAmount: '',
        // lastEmiDate: '',
        // tentativeTenure: '',
        tentativeShift: '',
        selectEMI: '',
        nbfcId: 'RTcTsJVH',
        // company:''
      },
      emiTableData: [],
      newAllDetail: '',
      bankData: '',
      company: '',
      giftCardData: '',
      giftPopupState: false,
      giftLoader: false,
      belowoneLakhState: false,
      rangeVreedhi: '',
      salaryDateToShow: '',
      bankVerificationData: '',
      newloaderState: false,
      confirmationPopupState: false,
      confirmationText: '',
      nachSourceData: '',
      esignDisable: false,
      repeatUser: '',
      showCheckBoxEsign: false,
      loaderStateemi: false,
      errorMsg:''



    }
  }

  bankVerificationApiFunction() {
    const { userDetails } = this.props
    bankVerificationApi(this.state.bankData.ifscCode, this.state.bankData.accountNumber, this.state.bankData.nameAsBankAccount, userDetails.userId, callback => {
      if (callback !== 'null') {
        this.setState({ bankVerificationData: callback })
      }
    })
  }
  belowoneLakh(belowoneLakhState) {
    if (belowoneLakhState == true) {
      this.setState({ belowoneLakhState: false })
      let tentativeDetail = Object.assign({}, this.state.tentativeDetail)
      tentativeDetail.tentativeAmount = ''
      this.setState({
        tentativeDetail
      })
    }
    else if (belowoneLakhState == false) {
      this.setState({ belowoneLakhState: true })
      let tentativeDetail = Object.assign({}, this.state.tentativeDetail)
      tentativeDetail.tentativeAmount = ''
      this.setState({
        tentativeDetail
      })
    }

  }
  getGiftCardDetail(giftCardId) {
    this.setState({ giftLoader: true })

    if (giftCardId != null && giftCardId != '') {
      fetch(
        APIS.GET_GIFT_VOUCHER_DETAILS + giftCardId
      )
        .then(res => res.json())
        .then(json => {

          this.setState({ giftCardData: json, giftPopupState: true, giftLoader: false })

        })
    }
  }
  nbfcHandler(e) {
    this.setState({ nbfc: e.target.value })
    let tentativeDetail = Object.assign({}, this.state.tentativeDetail)
    tentativeDetail.nbfcId = e.target.value
    this.setState({ tentativeDetail })
  }
  componentWillMount() {
    const { userDetails, admin, allDetail } = this.props
    let nbfcName =
      admin.nbfcName != null && admin.nbfcName != '' ? admin.nbfcName : 'nbfc'
    getNbfcList(callBack => {
      this.setState({ nbfcList: callBack })
    })
    this.setState({ salaryDateToShow: allDetail.employmentDetailsPO.salaryDay })
    let tentativeDetail = Object.assign({}, this.state.tentativeDetail)

    if (userDetails.loanId != null && userDetails.loanId != '') {
      this.setState({ loaderStateemi: true })
      getUserDetailByLoanIdOptimisedApi(userDetails.loanId, userDetails.userId, callBack => {
        tentativeDetail.tentativeAmount = callBack.loanAmount
        tentativeDetail.selectEMI = callBack.loanEmi
        // callBack.tentativeAmount != null ? callBack.tentativeAmount : ''
        this.setState({ allDetail: callBack, tentativeDetail, loaderStateemi: false })
      })
    }
    let loanEmi =
      allDetail.tentativeAmount > 10000
        ? allDetail.loanEmi
        : 1
    if (userDetails.typeOfProduct == 'Loan') {
      if (userDetails.loanFrom === 'vreedhi') {
        calculateEmiNbfcApi(
          // userDetails.loanAmount,
          this.state.tentativeDetail.tentativeAmount,
          userDetails.loanEmi,
          allDetail.employmentDetailsPO.salaryDay,
          userDetails.loanFrom !== "vreedhi" ? nbfcName : 'vreedhi',
          callBack => {
            callBack.length > 0
              ? callBack.map((emi, i) => {
                if (emi.Date != undefined) {
                  tentativeDetail['tentativeTenure' + i] = emi.Date
                  // tentativeDetail['tentativeTenure' + i] = emi.Date
                  tentativeDetail['lastEmiDate' + i] = ''
                }
              })
              : null
            tentativeDetail.selectEMI = allDetail.loanEmi

            this.setState({ emiTableData: callBack, tentativeDetail })
          }
        )

      } else {
        this.setState({ loaderState: true })
        calculateEmiApi(
          // userDetails.loanAmount,
          this.state.tentativeDetail.tentativeAmount,
          userDetails.loanEmi,
          allDetail.employmentDetailsPO.salaryDay,
          callBack => {
            callBack.length > 0
              ? callBack.map((emi, i) => {
                tentativeDetail['tentativeTenure' + i] = emi.Date
                tentativeDetail['lastEmiDate' + i] = ''

              })
              : null
            tentativeDetail.selectEMI = allDetail.loanEmi
            this.setState({ emiTableData: callBack, tentativeDetail, loaderState: false })
          }
        )
      }
    } else if (userDetails.typeOfProduct == 'Gift Card') {
      this.setState({ loaderState: true })
      calculateEmiApiGiftCard(
        // userDetails.loanAmount,
        this.state.tentativeDetail.tentativeAmount,
        '3',
        allDetail.employmentDetailsPO.salaryDay,
        callBack => {
          callBack.length > 0
            ? callBack.map((emi, i) => {
              tentativeDetail['tentativeTenure' + i] = emi.Date
              tentativeDetail['lastEmiDate' + i] = ''
            })
            : null
          tentativeDetail.selectEMI = allDetail.loanEmi
          this.setState({ emiTableData: callBack, tentativeDetail, loaderState: false })
        }
      )
    }

  }
  closePopupGift() {
    this.setState({
      giftPopupState: false
    })
  }
  RfdFunction(data) {
    const { admin, userDetails } = this.props
    rfdApi(userDetails.loanId, data, admin.emailId, userDetails.userId, userDetails.giftCardId, callBack => {
      if (callBack === 'success') {
        this.setState({
          popupState: true,
          popupStatus: 'saved successfully'
        })
        this.removePopup()
      } else {
        this.setState({
          popupState: true,
          popupStatus: 'Please Try Again later!'
        })
        this.removePopup()
      }
    })
  }
  salaryhandler(e) {
    const { allDetail, admin, userDetails } = this.props

    let tentativeDetail = Object.assign({}, this.state.tentativeDetail)
    this.setState({ salaryDateToShow: e.target.value })


    let nbfcName =
      admin.nbfcName != null && admin.nbfcName != '' ? admin.nbfcName : 'nbfc'
    if (userDetails.typeOfProduct == 'Loan') {
      this.setState({ loaderState: true })
      calculateEmiNbfcApi(
        // userDetails.loanAmount,
        this.state.tentativeDetail.tentativeAmount,
        this.state.tentativeDetail.selectEMI,
        e.target.value,
        userDetails.loanFrom !== "vreedhi" ? nbfcName : 'vreedhi',
        callBack => {
          callBack.length > 0
            ? callBack.map((emi, i) => {
              if (emi.Date != undefined) {
                tentativeDetail['tentativeTenure' + i] = emi.Date
                // tentativeDetail['tentativeTenure' + i] = emi.Date
                tentativeDetail['lastEmiDate' + i] = ''
              }
            })
            : null
          // tentativeDetail.selectEMI = this.state.allDetail.loanEmi

          this.setState({ emiTableData: callBack, tentativeDetail, loaderState: false })
        }
      )
    } else if (userDetails.typeOfProduct == 'Gift Card') {
      let tentativeDetail = Object.assign({}, this.state.tentativeDetail)
      tentativeDetail.selectEMI = '3'
      this.setState({ loaderState: true })
      calculateEmiNbfcApi(
        // userDetails.loanAmount,
        this.state.tentativeDetail.tentativeAmount,
        '3',
        e.target.value,
        'giftCard',
        // userDetails.loanFrom !== "vreedhi" ? nbfcName : 'vreedhi',
        callBack => {
          callBack.length > 0
            ? callBack.map((emi, i) => {
              if (emi.Date != undefined) {
                tentativeDetail['tentativeTenure' + i] = emi.Date
                // tentativeDetail['tentativeTenure' + i] = emi.Date
                tentativeDetail['lastEmiDate' + i] = ''
              }
            })
            : null

          this.setState({ emiTableData: callBack, tentativeDetail, loaderState: false })
        }
      )
    }

    this.setState({
      tentativeDetail
    })

  }
  triggerKycButton() {
    const { admin, userDetails } = this.props
    this.setState({ newloaderState: true })
    triggerKycApi(userDetails, callBack => {
      // console.log(callBack)
      if (callBack === 'success') {
        this.setState({ newloaderState: false, popupState: true, popupStatus: 'successfully done!' }, () => this.removePopup())
      } else {
        this.setState({ newloaderState: false, popupState: true, popupStatus: 'please try again later!' }, () => this.removePopup())

      }

    })
  }
  cancelLoanConfirm(type) {
    const { admin, userDetails } = this.props

    if (type === 'yes') {
      let paybackDates = []
      this.state.emiTableData.length > 0
        ? this.state.emiTableData.map((emi, i) => {
          let dateValue = document.getElementById('RepaymentDate_' + i).value
          paybackDates.push(dateValue)
        })
        : null
      if (userDetails.typeOfProduct == 'Loan') {

        this.setState({ newloaderState: true })
        saveTentativeDataApi(
          this.state.tentativeDetail,
          paybackDates,
          userDetails,
          admin,
          this.state.esignDisable,
          callBack => {
            if (callBack == 'success') {
              this.setState(
                {
                  popupState: true,
                  popupStatus: 'Data saved successfully',
                  tentativePopupState: false,
                  newloaderState: false,
                  confirmationPopupState: false,
                },
                () => setTimeout(() => {
                  this.props.closePopup(false)
                }, 1000)
              )
              this.removePopup()
            } else {
              this.setState({
                confirmationPopupState: false,
                newloaderState: false,
                popupState: true,
                popupStatus: 'Please try again'
              })
              this.removePopup()
            }
          }
        )
      }
      if (userDetails.typeOfProduct == 'Gift Card') {
        saveTentativeDataGiftCard(
          this.state.tentativeDetail,
          paybackDates,
          userDetails,
          admin,
          this.state.company,
          callBack => {
            if (callBack == 'success') {
              this.setState({
                popupState: true,
                popupStatus: 'Data saved successfully',
                tentativePopupState: false,
                confirmationPopupState: false,

              })
              this.removePopup()
            } else {
              this.setState({
                confirmationPopupState: false,
                popupState: true,
                popupStatus: 'Please try again'
              })
              this.removePopup()
            }
          }
        )
      }
    }
    if (type === 'no') {
      this.setState({ confirmationPopupState: false, tentativePopupState: true })
    }

  }
  esignDisableHandler(e) {
    this.setState({ esignDisable: e.target.checked })
  }
  sendNoc(data) {
    this.setState({ loaderState: true })
    fetch(APIS.SEND_NOC_API + data)
      .then(res => res.text())
      .then(res => {
        if (res == 'success') {
          this.setState({
            popupState: true,
            popupStatus: 'Sent Suucessfully!',
            loaderState: false
          })
          this.removePopup()
        } else {
          this.setState({
            popupState: true,
            popupStatus: 'Please try again',
            loaderState: false
          })
          this.removePopup()
        }
      })

  }
  formatKey = (key) => {
    return key
        .replace(/_/g, ' ') 
        .replace(/\b\w/g, char => char.toUpperCase()); 
};

getFailureDescriptions = () => {
  const { mandatoryData } = this.props;

  const rows = [];
  for (const key in mandatoryData) {
      if (key === "final_decision") continue; // Ignore final_decision key
      if (mandatoryData[key].status === "false") {
          rows.push(
              <tr key={key}>
                  <td><b>{this.formatKey(key)}</b></td>
                  <td>
                      <span className='red-text' style={{  padding: '5px', borderRadius: '5px', fontSize: '10px' }}>
                          {mandatoryData[key].description}
                      </span>
                  </td>
              </tr>
          );
      }
  }
  return (
    <div className='phocket-table-new'>
      <table>
          <thead>
              <tr>
                  <th>Checkpoint</th>
                  <th>Description</th>
              </tr>
          </thead>
          <tbody>
              {rows}
          </tbody>
      </table>
      </div>
  );
};

  render() {
    const { admin, userDetails, errorMessage, showButton,mandatoryData } = this.props
    return (
      <div>
        {/* {console.log(jhygfdxcvbjhgfc)} */}
        {this.state.newloaderState ? <NewLoader /> : ""}
        {this.state.loaderState || this.state.loaderStateemi ? <Loader /> : ''}
        {this.state.popupState ? (
          <Popup
            closePopup={this.closePopup.bind(this)}
            popupStatus={this.state.popupStatus}
          />
        ) : (
          ''
        )}
        {this.state.giftPopupState ?
          <GiftPopup
            giftCardData={this.state.giftCardData}
            closePopup={this.closePopupGift.bind(this)}
          />
          : ""}
        {this.state.giftLoader ?
          <LoaderGift />
          : ""}
        <UserFinancial
        mandatoryData={mandatoryData}
        getFailureDescriptions={this.getFailureDescriptions.bind(this)}
        errorMsg={this.state.errorMsg}
          // nachSourceData={this.props.nachSourceData}
          errorMessage={errorMessage}
          showButton={showButton}
          disableUpDownScroll={this.disableUpDownScroll.bind(this)}
          closePopup={this.props.closePopup}
          sendNoc={this.sendNoc.bind(this)}
          showCheckBoxEsign={this.state.showCheckBoxEsign}
          repeatUser={this.state.repeatUser}
          esignDisable={this.state.esignDisable}
          esignDisableHandler={this.esignDisableHandler.bind(this)}
          nachSourceData={this.state.nachSourceData}
          triggerKycButton={this.triggerKycButton.bind(this)}
          bankVerificationData={this.state.bankVerificationData}
          salaryDateToShow={this.state.salaryDateToShow}
          salaryhandler={this.salaryhandler.bind(this)}
          rangeVreedhi={this.state.rangeVreedhi}
          RfdFunction={this.RfdFunction.bind(this)}
          belowoneLakhState={this.state.belowoneLakhState}
          belowoneLakh={this.belowoneLakh.bind(this)}
          hideDeactivateButton={this.props.hideDeactivateButton}
          getGiftCardDetail={this.getGiftCardDetail.bind(this)}
          company={this.state.company}
          userDetails={userDetails}
          bankData={this.state.bankData}
          nbfc={this.state.nbfc}
          nbfcHandler={this.nbfcHandler.bind(this)}
          nbfcList={this.state.nbfcList}
          allDetail={this.state.newAllDetail}
          repeatApplication={this.state.repeatApplication}
          tentativePopup={this.tentativePopup.bind(this)}
          tentativePopupState={this.state.tentativePopupState}
          tentativeData={this.tentativeData.bind(this)}
          tentativeSaveHandler={this.tentativeSaveHandler.bind(this)}
          tentativeDetail={this.state.tentativeDetail}
          admin={admin}
          emiTableData={this.state.emiTableData}
          convertLoan={this.convertLoan.bind(this)}
          emiConvertHandler={this.emiConvertHandler.bind(this)}
          mendateVerification={this.mendateVerification.bind(this)}
        />
        {this.state.BulletToEmiState == true ? (
          <BulletToEmiPopup
            loanDataForEmiChange={this.state.loanDataForEmiChange}
            getLoanState={this.getLoanState.bind(this)}
            userDetail={this.state.allDetail}
          />
        ) : null}
        {this.state.confirmationPopupState ?
          <div style={{ zIndex: '99999', position: 'absolute' }}>
            <ConfirmationPopup
              cancelLoanConfirm={this.cancelLoanConfirm.bind(this)}
              confirmationText={this.state.confirmationText}
            />
          </div>
          : ""}
      </div>
    )
  }

  mendateVerification() {
    mandateVarificationApi(this.state.allDetail.userId, this.state.allDetail.loanId, callBack => {
      if (callBack == 'success') {
        this.setState({
          popupState: true,
          popupStatus: 'Sent for verification!'
        })
        this.removePopup()
      } else {
        this.setState({
          popupState: true,
          popupStatus: 'Please try again'
        })
        this.removePopup()
      }
    })
  }

  emiConvertHandler(e) {
    fetch(
      APIS.UPDATE_CONVERT_EMI_BTN +
      this.state.allDetail.userId +
      '/' +
      e.target.checked
    )
      .then(res => res.text())
      .then(text => {
        if (text == 'success') {
          getUserDetailByLoanId(this.state.allDetail.loanId, callBack => {
            this.setState({
              allDetail: callBack,
              popupState: true,
              popupStatus: 'saved successfully'
            })
            this.removePopup()
          })
        }
      })
  }

  convertLoan(loanData) {
    this.setState({
      BulletToEmiState: true,
      loanDataForEmiChange: loanData
    })
  }

  getLoanState(updatePopupState) {
    this.setState({
      BulletToEmiState: updatePopupState
    })
    getUserDetailByLoanId(this.state.allDetail.loanId, callBack => {
      this.setState({ allDetail: callBack })
    })
  }

  removePopup() {
    setTimeout(
      function () {
        this.setState({ popupState: false })
      }.bind(this),
      5000
    )
  }

  closePopup() {
    this.setState({ popupState: false })
  }
  checkKyc() {
    const { userDetails } = this.props
    fetch(APIS.GET_PAN_NOTIFY + this.state.allDetail.userId)
      .then(res => res.json())
      .then(res => {
        if (res && res.esign) {
          this.setState({ esignDisable: res.esign })

        }
      })
  }
  componentDidMount() {
    const { userDetails } = this.props
    // console.log(this.props,'finance')
    // this.checkKyc()
    fetch(APIS.CHECK_REPEAT_USER + this.state.allDetail.userId)
      .then(res => res.json())
      .then(res => {
        this.setState({ repeatUser: res })
        if (res.repeatUser === true) {
          fetch(APIS.SHOW_DISABLE_ESIGN + userDetails.userId)
            .then(res => res.json())
            .then(json => {
              if (json !== null && json !== '' && json !== undefined) {
                this.setState({ esignDisable: json.esign, showCheckBoxEsign: json.esign })
              }
            })
        }
      })

    // for (let i = 0; i < this.state.allDetail.loanDetailList.length; i++) {
    //   let loanData = this.state.allDetail.loanDetailList[i]
    //   if (loanData.loanSanctionStatus == 'Closed') {
    //     this.setState({ repeatApplication: true })
    //     break
    //   }
    // }
    if (userDetails.nachStatus == true) {
      getSourceOfEnach(userDetails.userId, callBack => {
        this.setState({ nachSourceData: callBack })
      })
    }
    // this.setState({ loaderStateemi: true })
    // getLoanDetailByUserId(this.state.allDetail.userId, callBack => {
    //   if (callBack != '') {
    //     let tentativeDetail = Object.assign({}, this.state.tentativeDetail)
    //     tentativeDetail.tentativeAmount = callBack.loanAmount
    //     tentativeDetail.selectEMI = callBack.loanEmi
    //     this.setState({ tentativeDetail, loaderStateemi: false })
    //   }
    //   this.setState({

    //     newAllDetail: callBack,
    //     // loaderState: false
    //   })
    // })

    fetch(APIS.GET_ACCOUNT_DETAILS_USER_ID + userDetails.userId)
      .then(res => res.json())
      .then(json => {
        this.setState({ bankData: json }
          , () => this.bankVerificationApiFunction()
        )
      })


  }
  disableUpDownScroll(e) {
    e.preventDefault();
    console.log(e)
    if (e.which === 38 || e.which === 40) {
      e.preventDefault();
    }

  }
  tentativeData(e, tType, index) {

    const { userDetails } = this.props

    let tentativeDetail = Object.assign({}, this.state.tentativeDetail)
    if (tType == 'amount') {
      // tentativeDetail.tentativeAmount = e.target.value
      if (this.state.belowoneLakhState == false && e.target.value <= 100000 && userDetails.loanFrom !== 'vreedhi') {
        tentativeDetail.tentativeAmount = e.target.value
      } else if (this.state.belowoneLakhState == false && e.target.value > 100000 && userDetails.loanFrom !== 'vreedhi') {
        alert('Kindly tick the check box provided')

      }
      if (this.state.belowoneLakhState == true) {
        tentativeDetail.tentativeAmount = e.target.value
      }
      if (userDetails.loanFrom === 'vreedhi') {
        tentativeDetail.tentativeAmount = e.target.value
      }
    } else if (tType == 'tenure') {
      // tentativeDetail.lastEmiDate = e
      // tentativeDetail.tentativeTenure = _formatDateInDash(e)

      // this.state.emiTableData.length > 0
      //   ? this.state.emiTableData.map((emi, i) => {
      //     if (i == index) {
      //       tentativeDetail['lastEmiDate' + i] = e
      //       tentativeDetail['tentativeTenure' + i] = _formatDateInDash(e)
      //     }
      //   })
      //   : null

      this.state.emiTableData.length > 0
  ? this.state.emiTableData.map((emi, i) => {
      if (i == index) {
        if (index === 0) {
          // If it's the first EMI
          tentativeDetail['lastEmiDate' + i] = e;
          tentativeDetail['tentativeTenure' + i] = _formatDateInDash(e);
        } else if (index > 0) {
          // For subsequent EMIs
          let previousEmi = moment(
            tentativeDetail['tentativeTenure' + (index - 1)],
            'DD-MM-YYYY'
          );
          let currentEmi = moment(new Date(e));
          let emiDateDiff = currentEmi.diff(previousEmi, 'days');

          // Check if the difference is within the allowed range
          if (emiDateDiff >= 25 && emiDateDiff <= 35) {
            tentativeDetail['lastEmiDate' + i] = e;
            tentativeDetail['tentativeTenure' + i] = _formatDateInDash(e);
            this.setState({
              errorMsg: ''
            });
          } else {
            // Set an error message if the difference is outside the range
            this.setState({
              errorMsg:
                'Difference between 2 EMIs should be 25-35 days, but your date difference is ' +
                emiDateDiff +
                ' days.'
            });
          }
        }
      }
    })
  : null;
    } else if (tType == 'shift') {
      tentativeDetail.tentativeShift = e.target.value
    } else if (tType == TYPE.SELECT_EMI) {
      tentativeDetail.selectEMI = e.target.value
      if (userDetails.loanFrom === 'vreedhi') {
        if (e.target.value < 26 || e.target.value > 104) {
          e.target.value = ''
          this.setState({ rangeVreedhi: 'Please Enter Between 26 to 104 Weeks' })
        } else {
          this.setState({ rangeVreedhi: '' })

        }
      }
      if (userDetails.loanFrom === 'vreedhi') {
        calculateEmiNbfcApi(
          // userDetails.loanAmount,
          this.state.tentativeDetail.tentativeAmount,
          e.target.value,
          this.state.allDetail.employmentDetailsPO.salaryDay,
          userDetails.loanFrom !== "vreedhi" ? nbfcName : 'vreedhi',
          callBack => {
            callBack.length > 0
              ? callBack.map((emi, i) => {
                if (emi.Date != undefined) {
                  tentativeDetail['tentativeTenure' + i] = emi.Date
                  // tentativeDetail['tentativeTenure' + i] = emi.Date
                  tentativeDetail['lastEmiDate' + i] = ''
                }
              })
              : null

            this.setState({ emiTableData: callBack, tentativeDetail })
          }
        )

      } else {
        this.setState({ loaderState: true })
        calculateEmiApi(
          // userDetails.loanAmount,
          this.state.tentativeDetail.tentativeAmount,
          e.target.value,
          this.state.salaryDateToShow !== '' ? this.state.salaryDateToShow : this.state.allDetail.employmentDetailsPO.salaryDay,
          callBack => {
            callBack.length > 0
              ? callBack.map((emi, i) => {
                tentativeDetail['tentativeTenure' + i] = emi.Date
                tentativeDetail['lastEmiDate' + i] = ''
              })
              : null

            this.setState({ emiTableData: callBack, tentativeDetail, loaderState: false })
          }
        )
      }
    } else if (tType == 'company') {
      // tentativeDetail.company=e.target.value
      this.setState({ company: e.target.value })
    }
    this.setState({ tentativeDetail })
  }

  tentativeSaveHandler() {
    const { admin, userDetails } = this.props
    let paybackDates = []
    this.state.emiTableData.length > 0
      ? this.state.emiTableData.map((emi, i) => {
        let dateValue = document.getElementById('RepaymentDate_' + i).value
        paybackDates.push(dateValue)
      })
      : null
    if (
      this.state.tentativeDetail.nbfcId != '' &&
      this.state.tentativeDetail.tentativeAmount != ''
      // &&
      // this.state.tentativeDetail.tentativeShift != ''
    ) {
      if (userDetails.typeOfProduct == 'Loan' && this.state.rangeVreedhi === '') {
        if (this.state.tentativeDetail.tentativeAmount < userDetails.loanAmount) {
          this.setState({ newloaderState: true })
          saveTentativeDataApi(
            this.state.tentativeDetail,
            paybackDates,
            userDetails,
            admin,
            this.state.esignDisable,
            callBack => {
              if (callBack == 'success') {
                this.setState(
                  {
                    popupState: true,
                    popupStatus: 'Data saved successfully',
                    tentativePopupState: false,
                    newloaderState: false
                  }, () => {
                    setTimeout(() => {
                      this.props.closePopup(false)
                    }, 1000);
                  }
                )

                this.removePopup()
              } else {
                this.setState({
                  newloaderState: false,
                  popupState: true,
                  popupStatus: 'Please try again'
                })
                this.removePopup()
              }
            }
          )
        } else {
          this.setState({ confirmationPopupState: true, confirmationText: 'Approved amount is greater than or equal to applied amount! Do you want to proceed anyway?' })
        }
      } else if (userDetails.typeOfProduct == 'Gift Card' && this.state.rangeVreedhi === '') {
        if (this.state.tentativeDetail.tentativeAmount < userDetails.loanAmount) {

          saveTentativeDataGiftCard(
            this.state.tentativeDetail,
            paybackDates,
            userDetails,
            admin,
            this.state.company,
            callBack => {
              if (callBack == 'success') {
                this.setState({
                  popupState: true,
                  popupStatus: 'Data saved successfully',
                  tentativePopupState: false
                })
                this.removePopup()
              } else {
                this.setState({
                  popupState: true,
                  popupStatus: 'Please try again'
                })
                this.removePopup()
              }
            }
          )
        } else {
          this.setState({ confirmationPopupState: true, confirmationText: 'Approved amount is greater than or equal to applied amount! Do you want to proceed anyway?' })
        }
      }
    } else {
      this.setState({
        popupState: true,
        popupStatus: 'Please fill the details'
      })
      this.removePopup()
    }
  }

  tentativePopup(type) {
    const { userDetails } = this.props
    if (type == 'open') {
      this.setState({ tentativePopupState: true })
      // let tentativeDetail=Object.assign({},this.state.tentativeDetail)
      // tentativeDetail.company=this.props.userDetails.giftCardType
      this.setState({ company: userDetails.giftCardType })
    } else if (type == 'close') {
      this.setState({ tentativePopupState: false })
    }
  }
}
const mapStateToProps = ({ pendingDetail }) => {
  return {
    pendingDetail: pendingDetail
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(FinancialUser)
