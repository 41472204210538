import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { getAllExpense } from '../../targetactioncreator';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Loader from '../../../../../presentationals/Loader/Loader.component';
import CategoryMain from './CategoryMain';
import AddExpense from '../expenseaddnew'
const differenceDate = () => {
    let d = new Date()
    let todaysDate = d.getDate();
    return todaysDate - 1
}

const InitalDate = (type) => {
    const FirstDay = moment().subtract(differenceDate(), 'days');
    if (type === 'fromDate') {
        const fromdate = FirstDay.format('YYYY-MM-DD');
        return fromdate
    } else if (type === 'toDate') {
        const todate = moment(new Date).format('YYYY-MM-DD')
        return todate
    }
}

const ExpenseDashboard = ({admin}) => {
    const [loader, setLoader] = useState(false)
    const [editData, seteditData] = useState({})

    const [expenseData, setExpenseData] = useState({


    });

    const [graphData, setgraphData] = useState({})
    const [chartSeries, setchartSeries] = useState([])
    const [chartLabels, setchartLabels] = useState([])

    const [startDate, setStartDate] = useState(InitalDate('fromDate'));
    const [endDate, setEndDate] = useState(InitalDate('toDate'));
    const [selectedData, setSelectedData] = useState({});
    const [categoryOverviewScreen, setcategoryOverviewScreen] = useState(false)
    const [viewType, setviewType] = useState('list')

    useEffect(() => {
        getExpenseData(InitalDate('fromDate'), InitalDate('toDate'))
    }, []);

    const getExpenseData = (fromDate, toDate) => {
        const start = moment(fromDate).format('DD-MM-YYYY')
        const end = moment(toDate).format('DD-MM-YYYY')
        setLoader(true)
        getAllExpense(start, end, callback => {
            console.log(callback)
            if (callback && callback?.status == 200 && callback?.message == "success") {
                const data = callback?.data?.graphData || {};
                setchartSeries(Object.values(data).map(item => item?.expenseAmount || 0));
                setchartLabels(Object.keys(data));
                setExpenseData(callback?.data);
                setgraphData(data);
            } else {
                setExpenseData({});
                setgraphData({});
                setchartSeries([])
                setchartLabels([]);
            }
            setLoader(false)
        });
    }




    const chartColors = [
        '#80b7f7',
        '#77FF8F',
        '#FF8F73',
        '#F1C54C',
        '#9B5FBD',
        '#7EC2E0',
        '#76F19E',
        '#F1988D',
        '#BB7FE2',
        '#607A82'
    ];

    const chartOptions = {
        chart: {
            type: 'donut',
        },
        labels: chartLabels,
        colors: chartColors,
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: true,
            formatter: (val, opts) => {
                // Show raw values from the series
                const index = opts.seriesIndex;
                return chartSeries[index];
            },
        },
        tooltip: {
            y: {
                formatter: (val) => val, // Show raw values in the tooltip
            },
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom',
                }
            }
        }],
    };

    const handleClick = () => {
        getExpenseData(startDate, endDate)
    }

    const handleCategoryData = (category) => {
        setSelectedData(graphData[category])
        setcategoryOverviewScreen(true)
    }

    const handleBack = () => {
        setSelectedData({})
        setcategoryOverviewScreen(false)
    }

    return (
        <div className="NewExpenseManagementCSS MainDashboard">
            {
                loader ? <Loader /> : ''
            }
          {viewType==='add'?
           <button className='btn btn-primary' onClick={()=>setviewType('list')}>Back</button>
        :""}
            {viewType === 'list' ?
                <>
               {!categoryOverviewScreen? <button className='btn btn-primary' onClick={()=>setviewType('add')}><i className='fa fa-add'></i>Add Expense</button>:""}
                    {
                        categoryOverviewScreen ?
                            <CategoryMain selectedData={selectedData} startDate={startDate} endDate={endDate} handleBack={handleBack}  admin={admin} />
                            :
                            <div className='container-fluid'>
                                <div className='row' style={{ marginBottom: '40px' }}>
                                    <div className='col-sm-12 col-xs-12' style={{ marginTop: '30px' }}>
                                        <div className='datePickercss'>
                                            <div className="row">
                                                <div className="col-sm-2 col-xs-12">

                                                    <label>Start Date</label>
                                                    <input type="date" id='customDateInput' className='form-control' value={startDate} onChange={(e) => setStartDate(e.target.value)} />

                                                </div>
                                                <div className="col-sm-2 col-xs-12">
                                                    <label>End Date</label>
                                                    <input type="date" id='customDateInput' className='form-control' value={endDate} onChange={(e) => setEndDate(e.target.value)} />

                                                </div>
                                                <div className="col-sm-2 col-xs-12">
                                                    <button className='btn' onClick={() => handleClick()} style={{ background: '#337ab7', color: '#fff', padding: '10px', marginTop: '22px' }}>Apply Filter</button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                  
                                    <div className='col-sm-3 col-xs-12'>
                                        <div className='shadow-card' style={{ marginBottom: '20px' }}>
                                            <h4 className="cardh4"><b>{(expenseData?.calculation?.expenseTarget || 0)?.toLocaleString("en-US")}</b></h4>
                                            <label className="cardLabel">Expense Target</label>
                                        </div>
                                    </div>
                                    <div className='col-sm-3 col-xs-12'>
                                        <div className='shadow-card' style={{ marginBottom: '20px' }}>
                                            <h4 className="cardh4"><b>{(expenseData?.calculation?.expense || 0)?.toLocaleString("en-US")}</b></h4>
                                            <label className="cardLabel">Expense</label>
                                        </div>
                                    </div>
                                    <div className='col-sm-3 col-xs-12'>
                                        <div className='shadow-card' style={{ marginBottom: '20px' }}>
                                            <h4 className="cardh4"><b>{(expenseData?.calculation?.balance || 0)?.toLocaleString("en-US")}</b></h4>
                                            <label className="cardLabel">Balance</label>
                                        </div>
                                    </div>
                                    <div className='col-sm-3 col-xs-12'>
                                        <div className='shadow-card' style={{ marginBottom: '20px' }}>
                                            <h4 className="cardh4"><b>{(expenseData?.calculation?.noOfTransaction || 0)?.toLocaleString("en-US")}</b></h4>
                                            <label className="cardLabel">Transactions</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='shadow-card' style={{ marginTop: '30px' }}>
                                    <div className='row'>

                                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                                            <div style={{ minHeight: '450px' }}>
                                                <ReactApexChart options={chartOptions} series={chartSeries} type="donut" height={400} width="100%" />
                                            </div>
                                        </div>
                                        <div className='col-sm-6 col-xs-12' style={{ marginTop: '30px' }}>
                                            <div style={{ minHeight: '450px' }}>
                                                <ul>
                                                    {Object.keys(graphData).map((category, index) => (
                                                        <div key={index} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                            <div style={{ width: '30%', display: 'flex' }}>
                                                                <span
                                                                    style={{
                                                                        display: 'inline-block',
                                                                        width: '20px',
                                                                        height: '20px',
                                                                        borderRadius: '50%',
                                                                        backgroundColor: chartColors[index % chartColors.length],
                                                                        marginRight: '10px',
                                                                        marginTop: '10px'
                                                                    }}
                                                                ></span>
                                                                <div style={{ marginTop: '10px', cursor: 'pointer' }} onClick={() => handleCategoryData(category)}>{category}</div>
                                                            </div>
                                                            <div style={{ width: '50%', paddingTop: '8px' }}>
                                                                <div style={{ marginLeft: '20px' }}>
                                                                    {(graphData[category]?.expenseAmount)?.toLocaleString("en-US") || 0} &nbsp;&nbsp; {graphData[category]?.percentage || 0}%
                                                                </div>

                                                            </div>
                                                        </div>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </> : ""}
            {viewType === 'add' ?
                <AddExpense setViewType={setviewType} admin={admin} editData={editData} />
                : ""}
        </div>
    );
};

export default ExpenseDashboard;
