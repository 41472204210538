import React, { useState } from "react";
import ExpensesAdd from "./add";
import ExpensesList from "./list";
import moment from "moment";
import TuneIcon from '@mui/icons-material/Tune';


const MainTarget = () => {

    const [viewType, setviewType] = useState('list')
    const [editData, seteditData] = useState('')


    const editHandler = (data) => {
        console.log(data)
        if (data.id) {
            seteditData(data)
            setviewType('add')
        }
    }
   
    const ChangeScreen=(type)=>{
        seteditData('')
        setviewType(type)
    }
  
    return (
        <div className="container-fluid expense-config">
            <div style={{ display: 'flex', textAlign: 'center' ,marginTop:'30px',marginBottom:'-30px'}}>
                <div onClick={() => ChangeScreen('list')} style={{ color: viewType === 'list' ? '#fff' : '#000', background: viewType === 'list' ? '#337ab7' : '#f4f3f1', padding: '10px', borderRadius: '5px', width: '40px', height: '40px', cursor: 'pointer' }}> <i className="fa fa-list" ></i></div>&nbsp;&nbsp;
                <div onClick={() => ChangeScreen('add')} style={{ color: viewType === 'add' ? '#fff' : '#000', background: viewType === 'add' ? '#337ab7' : '#f4f3f1', padding: '10px', borderRadius: '5px', width: '40px', height: '40px', cursor: 'pointer' }}> <i className="fa fa-plus" ></i></div>

            </div>
         
           
            {viewType === 'list' ?
                <ExpensesList  editHandler={editHandler} />
                : ""}
            {viewType === 'add' ?
                <ExpensesAdd editData={editData} />

                : ""}
        </div>
    )
}
export default MainTarget