import React, { useState } from 'react'
import UnidentifiedPayment from './unidentifiedPayment'
import Allpayments from '../Payments/allpayments';
import UnverifiedPayments from '../Payments/UnverifiedPayments'
const TopTabStyle = {
    display: 'flex',
    justifyContent: 'end',
    marginBottom:'30%'
}
const tabStyle = {
    // width: '15%',
    background: 'rgb(219, 233, 255)',
    cursor: 'pointer',
    color: '#000',
    padding: '8px 12px',
    boxShadow: 'inset -3px -3px 10px -7px #000',
    borderTopLeftRadius:'6px',
    borderBottomLeftRadius:'6px',
};
const activeTabStyle = {
    // width: '15%',
    cursor: 'pointer',
    padding: '8px 12px',
    background: '#1eb0d2',
    color: '#fff',
    borderTopLeftRadius:'6px',
    borderBottomLeftRadius:'6px',
};
const h5Style = {
    textAlign: 'center',
    fontSize: '17px',
    margin: '5px 0px',
    writingMode: 'vertical-lr',
    transform:'rotate(180deg)'
}
export default function PaymentsMainTab({ admin, allStatus, newDataBackground, pageViewHandler }) {
    const [tab, setTab] = useState('Verified');

    const handlepagechange = (type) => {
        setTab(type)
    }
    return (
        <>
            <div className="" style={{ marginTop: '-12px',position:'relative' }}>
                <div style={{position:'fixed',right:'0'}}>
                <div className='topTabs' style={TopTabStyle}>
                    <div className="tabs" style={tab === "Verified" ? activeTabStyle : tabStyle} onClick={() => handlepagechange('Verified')}>
                        <h5 style={h5Style}>Verified</h5>
                    </div>
                </div>
                <div className='topTabs' style={TopTabStyle}>
                    <div className="tabs" style={tab === "Unverified" ? activeTabStyle : tabStyle} onClick={() => handlepagechange('Unverified')}>
                        <h5 style={h5Style}>Unverified</h5>
                    </div>
                </div>
                <div className='topTabs' style={TopTabStyle}>
                    <div className="tabs" style={tab === "unveried" ? activeTabStyle : tabStyle} onClick={() => handlepagechange('unveried')}>
                        <h5 style={h5Style}>Unidentified</h5>
                    </div>
                </div>
                </div>

                <div style={{ marginTop: window.innerWidth > 1800 ? '75px' : '60px' }}>

                    {tab === 'Verified' ?
                        <Allpayments pageViewHandler={pageViewHandler} />
                        : ""}
                    {tab === 'Unverified' ?
                        <UnverifiedPayments pageViewHandler={pageViewHandler} />
                        : ""}


                    {tab === 'unveried' ?
                        <UnidentifiedPayment />
                        : ""}
                </div>
            </div>
        </>
    )
}
